
.contactBanner {
    width: 80vw;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px 0px;
    transform: skew(30deg);
    margin-bottom: 5vh;
}

.contactPanel {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    align-items: center;
    border: 3px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    height: 20vh;
    width: 80vw;
}

.contactPanel a {
    text-decoration:  none;
    text-align: center;
    padding: 5vh 0 5vh 0;
}

.contactPanel a:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
    
}