
.portfolioBanner {
    width: 80vw;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px 0px;
    transform: skew(30deg);
    margin-bottom: 5vh;
}

.portfolioPanel {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    justify-content: center;
    border: 3px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    margin: auto;
    height: 70vh;
    width: 80vw;
}

.pCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pCard * {
    margin: 0;
}

.pCard:hover {
    border: 1px solid rgba(255, 255, 255, 0.5)
}