
.navbar img {
    height: 23vh;
    width:  auto;
    padding: 1vh 0 4vh 3vw;
    filter: drop-shadow(2px 2px 2px white) 
            drop-shadow(-2px -2px 2px white)
            drop-shadow(-2px 2px 2px white) 
            drop-shadow(2px -2px 2px white);
}

.navbar li {
    margin: 2vh 2vw 4.5vh 0;
    padding: 2vh 0 2vh 0;
}

.navbar button {
    position: absolute;
    left: 2vw;
    width: 20vw;
    height: 6vh;
    opacity: 0;
    z-index: 1;
    translate: 0 -1vw;
}

.navbar ul {
    width: 10vw;
    padding: 0 1.5vw 0 3vw;
    list-style-type: none;
}

.navbar {
    position: relative;
    top: 3vh;
    width: 15vw;
    padding: 0 1vw 1vh 0;
    border-width: 1vh 2vw 1vh 0;
}

.navItemBorder {
    position: absolute;
    width: 30vw;
    height: 5vh;
    left: -26vw;
    margin: 2vh 2vw 2vh 0;
    border-width: 5px;
    border-style: solid;
    border-radius:0 2vw 0 0;
    -moz-border-radius:0 2vw 0 0;
    -webkit-border-radius:0 2vw 0 0;  
    filter: drop-shadow(2px 2px 2px white) 
            drop-shadow(-2px -2px 2px white)
            drop-shadow(-2px 2px 2px white) 
            drop-shadow(2px -2px 2px white);
}

.open {
    animation: open 0.25s linear 0s forwards;
}

.open h2 {
    margin: 0 1vw 0 0;
    float: right;
}

.closed {
    animation: close 0.25s linear 0s backwards;
}

.about {
    top: 28.5vh;
}

.experience {
    top: 37vh;
}

.contact {
    top: 45.25vh;
}

.more {
    top: 53.5vh;
}

.border {
    position: absolute;
    height: 50vh;
    width: 4vw;
    top: 30vh;
    left: 0vw;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 1);
    border-radius:0 2vw 0 2vw;
    -moz-border-radius:0 2vw 0 2vw;
    -webkit-border-radius:0 2vw 0 2vw;
    filter: drop-shadow(2px 2px 2px white) 
            drop-shadow(-2px -2px 2px white)
            drop-shadow(-2px 2px 2px white) 
            drop-shadow(2px -2px 2px white);
}
 
@keyframes open {
    from { transform: translateX(0vw) }
    to { transform: translateX(16vw) }
}

@keyframes close {
    from { transform: translateX(16vw) }
    to { transform: translateX(0vw) }
} 