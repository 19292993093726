
.gridA {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(8vh, auto);
    grid-template-areas: 
        "a a b b b b b b"
        "a a b b b b b b"
        "c c b b b b b b"
        "c c b b b b b b";
}

.pic {
    grid-area: a;
    height: 35vh;
    margin: 4vh 0 0 4vh;
}

.bio {
    grid-area: b;
}

.bio:nth-child(2) {
    margin-top: 13vh;
}

.bio:nth-child(3) {
    margin-top: 18vh;
}

.bio:nth-child(4) {
    margin-top: 28vh;
}

.bio:nth-child(5) {
    margin-top: 40vh;
}

.alt {
    grid-area: c;
    display: block;
}

.alt div {
    height: 2vh;
    text-align: center;
    vertical-align: middle;
}

.alt div:nth-child(odd) {
    font-size: 2vh;
    margin-bottom: 1vh;
}

.alt div:nth-child(even) {
    font-size: 1.7vh;
    margin-bottom: 4vh;
    text-decoration: underline;
}

.alt div:nth-child(even)::after {
    content: "";
    width: 3px;
    height: 20px;
    background: black;
    display: inline-block;
    margin-left: 5px;
    animation: cursor-blink 0.5s steps(2) infinite;
}

@keyframes cursor-blink { 0% {opacity: 0;}}