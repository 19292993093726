
.gridE {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(8vh, auto);
    grid-template-areas: 
        "a a a a a a a a"
        "b b b b b b b b"
        "b b b b b b b b"
        "b b b b b b b b";
}

.menu {
    grid-area: a;
    border-bottom: solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.slider {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.Carousel-slider {
    height: 50vh
}

/* .slider-item {
    display: none;
} */
.menu h2 {
    padding: 1vh 1vw 1vh 1vw;
}

.highlight {
    border: thick double black ;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .dot:hover {
    background-color: black;
  }
  
  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 2.5s;
  }

  .dotMenu {
    position: absolute;
    width: 12vw;
    bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  @keyframes fadeInE {
    from {
        transform: translateX(100%);
        opacity: .4
    }
    to {
        transform: translateX(0%);
        opacity: 1
    }
  }

  @keyframes fadeOutE {
    from {
        transform: translateX(0%);
        opacity: 1
    }
    to {
        transform: translateX(-100%);
        opacity: .4
    }
  }