
/* .container {
 
} */


.page {
  position: absolute;
  top: 2vh;
  padding: 0 0 0vh 22vw;
  justify-content: center;
  width: 73vw;
  height: 84vh;
}

.hidden {
  opacity: 0;
}

.Panel {
  justify-content: center;
  border: 0.5vh solid rgba(0, 0, 0, 1);
  height: 70vh;
  margin: 10vh 0 0 0;
  filter: drop-shadow(2px 2px 2px white) 
          drop-shadow(-2px -2px 2px white)
          drop-shadow(-2px 2px 2px white) 
          drop-shadow(2px -2px 2px white);
}

.indent {
  text-indent: 3vw;
}

.fadeIn {
  animation: fade-in 2s;
}

.fadeOut {
  animation: fade-out 2s;
}

h1 {
  font-size: 3.25vh;
  padding: 2vh 0 0 10vw;
  transform: skew(-45deg);
}

h2 {
  font-size: 2.5vh;
}

h5 {
  position: absolute;
  top: 97vh;
  left: 1vw;
  font-size: 1vh;
  filter: drop-shadow(2px 2px 2px white) 
          drop-shadow(-2px -2px 2px white)
          drop-shadow(-2px 2px 2px white) 
          drop-shadow(2px -2px 2px white);
}



p {
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 2vh;
}

li {
  font-size: 2vh;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px), (min-width: 1800px), (max-height: 800px), (min-height: 1800px) {
  h1 {
    font-size: 3.25vh;
  }
  
  h2 {
    font-size: 2.5vh;
  }
  
  p {
    font-size: 2vh;
  }
  
  li {
    font-size: 2vh
  }
} 